import { Stack, Box } from "@mui/material";
import SvgIconFromUrl from "./SvgIconFromUrl";
import {NextStepItem} from "../../shared/ProviderData";

interface StepsBlockProps {
  title?: string;
  steps: NextStepItem[];
  className?: string;
}

const styles = {
  block: {
    alignItems: "center",
    gap: "10px"
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "28px",
    letterSpacing: "0.18px",
    color: "#2A2F31",
  },
  iconCol: {
    alignItems: "center",
    gap: "5px",
  },
  iconCols: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    gap: "20px",
  },
  iconText: {
    fontSize: "14px",
    lineHeight: "20px",
    width: "100px",
    textAlign: "center"
  },
};

const IconItem = (el: NextStepItem, index: number) => {
  const number = index + 1;

  return (
    <Stack key={index} sx={styles.iconCol}>
      <SvgIconFromUrl icon={el.icon.src} width={40} height={40} />
      <Box sx={styles.iconText}>{`${number}. ${el.icon.caption}`}</Box>
      {el.icon.caption2 && <Box sx={styles.iconText} mt={'-4px'}>{el.icon.caption2}</Box>}
    </Stack>
  );
};

const IconCols = ({items}: {items: NextStepItem[]}) => {
  return (
    <Stack sx={styles.iconCols}>
        {items?.map((el, index) => IconItem(el, index))}
    </Stack>
  );
};

export default function NextStepsBlock({ title = "NEXT STEPS", steps, ...props }: StepsBlockProps) {
  const iconItems = steps.filter(el => el.icon);
  const textItems = steps.filter(el => el.text);

  return (
    <Stack {...props} sx={styles.block}>
      <Box sx={styles.title}>{title}</Box>
      <IconCols items={iconItems} />
      {textItems.map((el, index) => (
        <Box textAlign="center" fontSize="14px" key={index}>{el.text}</Box>
      ))}
    </Stack>
  );
}
