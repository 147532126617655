import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const canvas = document.createElement("canvas");

function numberOfLines(element: any) {
  const style = window.getComputedStyle(element);
  const elementHeight = element.offsetHeight;
  const lineHeight = parseInt(style.lineHeight);

  return Math.round(elementHeight / lineHeight);
}

function getTextWidth(text: string, font: string): number {
  const context = canvas.getContext("2d");

  if (!context) return 0;

  context.font = font;

  const metrics = context.measureText(text);
  
  return metrics.width;
}

function getSecondLine(text: string, font: string, elementWidth: number) {
  const words = text.split(/\s+/);
  let currentLine = '';
  let secondLine = '';
  let currentWidth = 0;

  for (const word of words) {
      const wordWidth = getTextWidth(word + ' ', font);
      if (currentWidth + wordWidth > elementWidth) {
          if (currentLine === '') {
              secondLine = word;
          } else {
              secondLine += ' ' + word;
          }
          currentWidth = wordWidth;
      } else {
          currentLine += ' ' + word;
          currentWidth += wordWidth;
      }
  }

  return secondLine;
}

function getSecondLineTextWidth(element: any) {
  const style = window.getComputedStyle(element);
  const font = style.font;
  const elementWidth = element.offsetWidth;
  const text = element.innerText || element.textContent;

  if (numberOfLines(element) < 2) {
      return 0;
  }

  const secondLine = getSecondLine(text, font, elementWidth);

  return getTextWidth(secondLine, font);
}

interface TextLogoProps {
  text: string;
  className?: string;
}

export default function TextLogo({ text, className }: TextLogoProps) {
  const [fontSize, setFontSize] = useState(26);
  const [lineHeight, setLineHeight] = useState(28);
  const containerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      adjustTextSize();
    };

    const adjustTextSize = () => {
      const container = containerRef.current as any;
      const textElement = textRef.current;

      if (container && textElement) {
        const containerWidth = container.clientWidth;
        const secondLineWidth = getSecondLineTextWidth(textElement);

        if (secondLineWidth >= containerWidth) {
          setFontSize(24);
          setLineHeight(26);
        } else {
          setFontSize(26);
          setLineHeight(28);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    adjustTextSize(); // Initial adjustment

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text]);

  const styles = {
    container: {
      color: '#2A2F31',
      height: 'auto !important',
      minHeight: '28px',
      margin: '0 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontStyle: 'normal !important',
      fontSize: `${fontSize}px !important`,
      fontWeight: '400 !important',
      lineHeight: `${lineHeight}px !important`,
      letterSpacing: '-2%',
      display: '-webkit-box !important',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
  };

  return (
    <Box className={`provider-logo ${className}`} ref={containerRef} sx={styles.container}>
      <Typography ref={textRef} sx={styles.text}>
        {text}
      </Typography>
    </Box>
  );
};