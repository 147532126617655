import axios from "../axios";
import {OffersProviderDataMC} from "../store/actions/vehicles";

interface Benefit {
    icon: string;
    text: string;
}

interface DocumentStepItem {
    icon: string;
    caption: string;
}
export interface NextStepIcon {
  src: string;
  caption: string;
  caption2?: string;
}
export interface NextStepItem {
    icon: NextStepIcon;
    text: string;
}

export interface OfferDetails {
    benefits: Benefit[];
    documents: DocumentStepItem[];
    nextSteps: NextStepItem[];
}

export interface Card {
    logo?: string;
    storeLogo?: string;
    logoType?: string;
    offerDetails: OfferDetails;
    includeLocationName?: string;
    includeBuyCenter?: string;
    useLocationLogo?: string;
    useCompanyLogo?: string;
}

interface NextStepText {
    body: [];
    header: string;
}

interface FormItem {
    name: string;
    scenario: string;
    excludeFields: string[];
}

interface Data {
    nextStepText: NextStepText;
    details: string[];
    card: Card;
    forms?: FormItem[]
}

export interface ProviderData {

    locationId: string;
    data: Data
    ;
}

export class ProviderData {
    static async get(data : OffersProviderDataMC[]) {
        const res = await axios.post('/offer-providers' , {matchingClients : data});
        return res?.data?.offerProviders
    }
}