import { Box } from '@mui/material';
import TextLogo from './TextLogo';

interface LogoProps {
  type?: string;
  text?: string;
  src?: string;
  className?: string;
  isLogoHidden?: boolean;
  primaryName?: string;
  logoStyles?: object;
}

const Logo = ({
  text = '',
  src,
  isLogoHidden,
  primaryName,
  className,
  logoStyles = {}
}: LogoProps) => {
  const renderLogo = (useText?: boolean) => {
    return useText ? (
      <TextLogo
        text={text}
        className={className}
      />
    ) : (
      <Box
        component={'img'}
        src={src}
        className={`provider-logo provider-logo-${primaryName} ${className}`}
        sx={logoStyles}
      />
    );
  };

  if (isLogoHidden) {
    return <TextLogo text={'Offer A'} />;
  }

  function isValidURL(string: string): boolean {
    const regex =
      /((http|ftp)s?:\/\/[^\s]+)|(www\..+?\.\w+\/[^\s]+)|([\w\d]+\.(com|org)\/[^\s]+)/g;
    return regex.test(string);
  }

  return renderLogo(src ? !isValidURL(src) : true);
};

export default Logo;
