import moment from "moment";
import {Offer, OfferRefreshSource, OfferStatuses, OfferTypes} from "../store/actions/offers";

export class OfferHelper {
    static isOfferExpired = (offer: Offer) => {
        if (!offer || !offer.expiresAt) {
            return false;
        }
        const isExpired  = moment(offer.expiresAt).utc() < moment().utc()
        if(isExpired && offer.type === OfferTypes.Manual && offer.refreshSource === OfferRefreshSource.Carwiser)
            return false

        return isExpired
    }
    
    static isOfferInProgress = (offer: Offer) => {
        const isExpired  = moment(offer.expiresAt).utc() < moment().utc()
        return (offer && !offer.amount) || (offer.type === OfferTypes.Manual && offer.refreshSource === OfferRefreshSource.Carwiser && isExpired);
    }

    static hasOfferLink = (offer: Offer) => {
        return offer && offer.offerLink;
    }
    
    static canSelectOffer = (offer: Offer) => {
        return offer && !this.isOfferInProgress(offer)
            && !this.isOfferExpired(offer) 
            && offer.status === OfferStatuses.Active;
    }

    static canOpenOfferLink = (offer: Offer) => {
        return offer && !this.isOfferInProgress(offer)
            && !this.isOfferExpired(offer)
            && [OfferStatuses.Active, OfferStatuses.Accepted].includes(offer.status);
    }
    
    static generateClass = (offer: any, section = '', prefix = '') => {
      let badge;

      for (let key in offer?.badges) {
        if (['icon'].includes(key)) {
          continue;
        }

        const badgeObj = offer?.badges[key];

        if (badgeObj?.status) {
          badge = key;
          break;
        }
      }

      return (
        ' ' +
        `provider-${offer.locationId}${badge ? '-' + badge : ''}${
          section ? '-' + section : ''
        }${prefix ? '-' + prefix : ''}`
      );
    }
}