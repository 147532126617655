import {Stack, Box} from '@mui/material'
import Logo from './Logo';
import {Card} from "../../shared/ProviderData";

interface LogoBlockProps {
  cardInfo?: Card;
  locationName?: string;
  clientName: string;
  isLogoHidden?: boolean;
  primaryName?: string;
}

export default function LogoBlock({cardInfo, locationName, clientName, isLogoHidden, primaryName}: LogoBlockProps) {
  const renderBlock = () => {
    const items = [];
    const styles = {
      locationName: {
        fontSize: `24px !important`,
        fontWeight: '700 !important',
        textAlign: 'center',
        justifyContent: 'center',
        margin: '-14px 0 -6px 0',
        color: '#000000'
      },
      multilogo: {
        maxWidth: 'calc(50% - 5px)',
        objectFit: 'contain',
        margin: '0 auto -6px !important'
      }
    };

    if (cardInfo?.includeLocationName === '1') {
      items.push((
        <Box component={'div'} sx={styles.locationName} key='locationName'>
          {locationName}
        </Box>
      ));
    }

    if (cardInfo?.useCompanyLogo === '1' && cardInfo?.useLocationLogo === '1') {
      items.push((
        <Stack gap={'10px'} width={'100%'} direction="row" key='locationLogo'>
          <Logo src={cardInfo?.logo} logoStyles={styles.multilogo} />
          <Logo src={cardInfo?.storeLogo} logoStyles={styles.multilogo} />
        </Stack>
      ));
    } else if (cardInfo?.useCompanyLogo === '1' || cardInfo?.useLocationLogo === '1') {
      const src = cardInfo?.useCompanyLogo === '1' ? cardInfo?.logo : cardInfo?.storeLogo;

      items.push(<Logo src={src}  key='locationLogo'/>);
    }
    
    return items.length ? items : (
      <Logo
        text={clientName}
        src={cardInfo?.logo}
        isLogoHidden={isLogoHidden}
        primaryName={primaryName}/>
    )
  };

  return (
    <Stack className={'provider-logo-block'} gap={'10px'} flex={1} justifyContent={'center'} width={'100%'}>
      {renderBlock()}
    </Stack>
  );
}