import React from 'react'
import './Offer.css';
import { Box, Collapse, Icon } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { TimerOutlined } from '@mui/icons-material'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../app/store'
import { Button } from '../form/Button';
import { selectOffer, Offer, setSelectedOffer } from '../../store/actions/offers'
import OfferAmount from './OfferAmount'
import { OfferHelper } from '../../shared/offer-helper'
import {generateTag} from "../../shared/utility";
import LogoBlock from './LogoBlock'
import NextStepsBlock from './NextStepsBlock'
import SvgIconFromUrl from './SvgIconFromUrl'

import moment from 'moment'
import {Vehicle} from "../../store/actions/vehicles";
import {OfferDetails, ProviderData} from "../../shared/ProviderData";

const orderLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

class ItemDescriptionDto {
	color?: string;
	icon?: any;
	description?: string;
}

class OfferPropsDto {
	offer: any;
	index?: any;
	selectOffer?: any;
	setSelectedOffer?: any;
	loading?: boolean;
	type?: string;
	badge?: string;
	vehicle?: Vehicle;
}



function OfferComponent(props: OfferPropsDto) {
	// const theme = useTheme()
	// const isTableAndLess = useMediaQuery(theme.breakpoints.down('md'))

	const [open, setOpen] = React.useState(false)
	const [isLoading, setLoading] = React.useState(false);

	const handlerOpen = () => {
		if(!OfferHelper.isOfferExpired(props.offer)) {
			setOpen(!open)
		}
	}

	// const [openModal, setOpenModal] = useState(false)

	// const handleOpenModal = () => {
	// 	setOpenModal(true)
	// }

	// const handleCloseModal = () => {
	// 	setOpenModal(false)
	// }

	// const handleContactAgent = () => {
	// 	handleCloseModal()
	// 	// props.onSendCommentToContactAgent(props.offer)
	// }

	const navigate = useNavigate();
	const onToOffer = () => {
		if (!OfferHelper.canSelectOffer(props.offer)) {
			if (!OfferHelper.canOpenOfferLink(props.offer)) {
				return ;
			}
			props.setSelectedOffer(props.offer);
			navigate('/offer');
			return ;
		}

		setLoading(true);
		props.selectOffer(props.offer, (err: any) => {
			setLoading(false);
			if (err) {
				return ;
			}
			navigate('/offer');
		});
	}

	const controlStyles = {display: 'flex', cursor: 'pointer', fontSize: {xs: '48px'}, color: '#29aae1', marginTop: '-12px'}
	const control = open
		? <KeyboardArrowUpIcon
			sx={controlStyles}
			onClick={handlerOpen}
			id={generateTag(!open ? 'open' : 'close', {prefix: 'offer-card', snippet: props.offer.provider, suffix: 'btn'})}
		/>
		: <KeyboardArrowDownIcon
			sx={controlStyles}
			onClick={handlerOpen}
			id={generateTag(!open ? 'open' : 'close', {prefix: 'offer-card', snippet: props.offer.provider, suffix: 'btn'})}
		/>


	const provider = props.vehicle?.providers.find((el: {locationId:string} )=> el.locationId === props.offer?.locationId) as ProviderData | undefined ;
	const providerData = provider?.data
	const nextStep  =  providerData?.nextStepText ? providerData?.nextStepText : undefined

	const nextStepTextBody =nextStep &&  Array.isArray(nextStep.body) ?
		nextStep.body : [];
	const nextStepTextHeader =nextStep &&  nextStep?.header ?
		nextStep.header : '';

	const offerDetails =providerData && providerData?.card.offerDetails ? providerData.card.offerDetails : [];

	const isOfferExpired = OfferHelper.isOfferExpired(props.offer)
	
	const Badges = ({badges}: {badges: any}) => {
		let iconBadge: undefined | string
		let textBadge: undefined | string
		
		for (let key in badges) {
			if (['icon'].includes(key)) {
				continue
			}
			
			const badge = badges[key]
			
			if (badge.status) {
				iconBadge = badges.icon
				textBadge = badge.caption
				break
			}
		}
		
		if (iconBadge === undefined) {
			return null
		}
		
		if (textBadge === undefined) {
			return null
		}
		
		return (
			<Box className="toggle-badge">
				<SvgIconFromUrl icon={iconBadge}/>
				<span className={"text"}>{textBadge}</span>
			</Box>
		)
	}
	
	const getExpiresDate = (offer: any) => {
		if (isOfferExpired) {
			return `Expired`
		}
		
		if (offer.expiresAt) {
			return `Expires ${moment(offer.expiresAt).format('M/D/Y')}`
		}
		return null;
	}
	
	const expiresDate = getExpiresDate(props.offer);
	
	const FactElement = (benefit: any, index: number) => {
		return (
			<div className="fact-block-line" key={index}>
				<SvgIconFromUrl icon={benefit.icon}/>
				<div className="fact-text">{benefit.text}</div>
			</div>
		)
	}

	const HeaderBlock = ({offer, expiresDate}: {offer: any, expiresDate: any}) => {
		return (
			<>
				<OfferAmount offer={offer} />
				<Box className="toggle-info">
					{
						OfferHelper.isOfferInProgress(offer)
							? (
								<Box className="toggle-text">
									<span>Offer is in-progress, a Carwiser Agent will reach out once it is generated</span>
								</Box>
							)
							: (
								<>
									{providerData?.card.includeBuyCenter === '1' && <Box className="metric-tag">Buy Center</Box>}
									<Badges badges={offer?.badges}/>
									{
										expiresDate && (
											<Box className="toggle-expire information-block-line">
												<TimerOutlined sx={{color: '#E3342F'}}/>
												<div className="information-text red">{expiresDate}</div>
											</Box>
										)
									}
								</>
							)
					}
				</Box>
			</>
		)
	}

	const ButtonSubmit = (
			<Button
				onClick={onToOffer}
				disabled={!OfferHelper.canOpenOfferLink(props.offer) || props.loading}
				loading={isLoading}
				variant="contained"
				sx={{minWidth: '260px'}}
				id={generateTag('continue', {prefix: 'offer-card', snippet: props.offer.provider, suffix: 'btn'})}
			>
				CONTINUE
			</Button>
		)

	return (
		<Box className={'offer offer-block' + (open ? ' open' : '') + (isOfferExpired === true ? ' hide-toggle' : '') + OfferHelper.generateClass(props.offer)}>
			<Box className="details" onClick={handlerOpen} sx={{
				cursor: isOfferExpired === true ? 'inherit' : 'pointer'
			}}>
				<Box className="accordion-toggle">
					<Box className="ac-cont">
						<div className="toggle-text">DETAILS</div>
						<div className="toggle-icon">{control}</div>
					</Box>					
				</Box>
        <LogoBlock
          cardInfo={providerData?.card}
          clientName={props.offer.clientName}
          locationName={props.offer.locationName}
          isLogoHidden={props.offer?.isLogoHidden}
          primaryName={props.offer.clientName}
        />
				<HeaderBlock offer={props.offer} expiresDate={expiresDate}/>
				{/* <OfferDetails offer={props.offer} /> */}
			</Box>
			<Collapse in={open}>
				<Box className={`expansion-panel` + OfferHelper.generateClass(props.offer, 'expansion-panel')}>
					<Box className={'main-details' + OfferHelper.generateClass(props.offer, 'main-details')}>
						<Box className={`description-block` + OfferHelper.generateClass(props.offer, 'benefits')}>
							<Box className="description-header">BENEFITS</Box>
							<Box className="facts-block">
								<Box className="facts-block-cont">
									{offerDetails && (offerDetails as OfferDetails).benefits.map((el: any, index: number) => FactElement(el, index))}
								</Box>
							</Box>
						</Box>
						<NextStepsBlock
							steps={(offerDetails as OfferDetails).nextSteps}
							className={`description-block ${OfferHelper.generateClass(props.offer, 'next-steps')}`}
						/>
						<Box className={`description-block` + OfferHelper.generateClass(props.offer, 'documents')}>
							<Box className="description-header">DOCUMENTS YOU’LL NEED</Box>
							<Box className="icon-cols">
								{offerDetails && (offerDetails as OfferDetails).documents.map((el: any, index: number) => {
									return (
										<Box key={index} className="icon-col">
											<Box className="icon">
												<SvgIconFromUrl icon={el.icon.src} width={40} height={40}/>
											</Box>
											<Box className="text">{el.icon.caption}</Box>
										</Box>
									)
								})}
							</Box>
						</Box>
					</Box>
					<Box sx={{display: 'flex', justifyContent: 'center'}}>
						{ButtonSubmit}
					</Box>
				</Box>
			</Collapse>
		</Box>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.offers.loading,
		vehicle: state.vehicles.selected,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		selectOffer: (offer: Offer, cb: any) => dispatch(selectOffer(offer, cb)),
		setSelectedOffer: (offer: Offer) => dispatch(setSelectedOffer(offer)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferComponent)