import { Icon } from "@mui/material";

interface SvgIconProps {
  icon: string;
  width?: number;
  height?: number;
}

const styles = {
  img: {
    display: "flex",
    width: "inherit",
    height: "inherit",
  },
};

export default function SvgIconFromUrl({icon, width = 28, height = 28}: SvgIconProps) {
  return (
      <Icon style={{textAlign: 'center', width: `${width}px`, height: `${height}px`}}>
          <img src={icon} style={styles.img} />
      </Icon>
  )
}
